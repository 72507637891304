import React from "react";
import HeroImage from "../components/HeroImage";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Form from "../components/ContactContent";
import CardContainer from "../components/CardContainer";

const Contact = () => {
  return (
    <div>
      <Navbar />
      <HeroImage
        heading="EAST COAST LANDSCAPES"
        text="Contact Us For A Quote Today!"
      />
      <Form />
      <CardContainer />
      <Footer />
    </div>
  );
};

export default Contact;
