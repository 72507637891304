import React from "react";
import "./FooterStyles.css";
import {
  FaFacebook,
  FaEnvelope,
  FaPhoneAlt,
  FaSearchLocation,
} from "react-icons/fa";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="left">
          <h4>About Us</h4>
          <p>
            East Coast Landscapes is a Durham Based Hardscape Construction
            Company with over 25 Years Experience. We specialize in Interlock
            Brick, Retaining Walls, Steps, Fences, Decks, Landscape Lighting,
            Fire Pits and more.
          </p>
          <div className="social">
            <a
              href="https://www.facebook.com/East-Coast-Landscapes-103570621807155/"
              target="_blank"
              rel="noopener noreferrer "
            >
              <FaFacebook
                size={30}
                style={{ color: "#ffffff", marginRight: "2rem" }}
              />
            </a>

            <a href="mailto:eastcoastlandscapes1@gmail.com">
              <FaEnvelope
                size={30}
                style={{ color: "#ffffff", marginRight: "2rem" }}
              />
            </a>
            <a href="tel:289-356-2717">
              <FaPhoneAlt
                size={30}
                style={{ color: "#ffffff", marginRight: "1rem" }}
              />
            </a>
          </div>
        </div>

        <div className="right">
          <div>
            <h4>
              <FaSearchLocation
                size={20}
                style={{ color: "#ffffff", marginRight: "1rem" }}
              />
              <a
                href="https://goo.gl/maps/DkvzqhD6FvX1C4AT6"
                target="_blank"
                rel="noopener noreferrer"
              >
                5500 Enfield Rd, Hampton, ON, Canada
              </a>
            </h4>
          </div>
          <div className="phone">
            <h4>
              <FaPhoneAlt
                size={20}
                style={{ color: "#ffffff", marginRight: "1rem" }}
              />
              <a href="tel:289-356-2717">289-356-2717</a>
            </h4>
          </div>
          <div className="email">
            <h4>
              <FaEnvelope
                size={20}
                style={{ color: "#ffffff", marginRight: "1rem" }}
              />
              <a href="mailto:eastcoastlandscapes1@gmail.com">
                eastcoastlandscapes1@gmail.com
              </a>
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
