import React from "react";
import Navbar from "../components/Navbar";
import Video from "../components/Video";
import Footer from "../components/Footer";
import CardContainer from "../components/CardContainer";
import ServicesContent from "../components/ServicesContent";
import Blurb from "../components/Blurb";
import Reviews from "../components/Reviews";

const Home = () => {
  return (
    <>
      <Navbar />
      <Video />
      <Blurb />
      <ServicesContent />
      <CardContainer />
      <Reviews />
      <Footer />
    </>
  );
};

export default Home;
