import React from "react";
import "./CardContainerStyles.css";
// import { Link } from "react-router-dom";

const CardContainer = () => {
  return (
    <>
      <div className="services">
        <div className="card-container">
          <div className="card drivewayz">
            <h3>
              <a href="/contact">Interlock Driveways</a>
            </h3>
          </div>
          <div className="card stepz">
            <h3>
              <a href="/contact">Steps</a>
            </h3>
          </div>
          <div className="card patioz">
            <h3>
              <a href="/contact">Interlock Patios</a>
            </h3>
          </div>
          <div className="card wallz">
            <h3>
              <a href="/contact">Retaining Walls</a>
            </h3>
          </div>
          <div className="card fencez">
            <h3>
              <a href="/contact">Fences</a>
            </h3>
          </div>
          <div className="card deckz">
            <h3>
              <a href="/contact">Decks</a>
            </h3>
          </div>
          <div className="card lightingz">
            <h3>
              <a href="/contact">Landscape Lighting</a>
            </h3>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardContainer;
