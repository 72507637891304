import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import HeroImage from "../components/HeroImage";
import CardContainer from "../components/CardContainer";
import ServicesContent from "../components/ServicesContent";
import Form from "../components/ContactContent";
import Blurb from "../components/Blurb";

const Services = () => {
  return (
    <div>
      <Navbar />
      <HeroImage
        heading="SERVICES"
        text="''We Represent Quality Construction!''"
      />
      <Blurb />
      <CardContainer />
      <ServicesContent />
      <Form />
      <Footer />
    </div>
  );
};

export default Services;
