import React from "react";
import { FaPhone } from "react-icons/fa";
import "./ContactContentStyles.css";

const Form = () => {
  return (
    <div className="services2">
      <div className="contact-container">
        <div className="text-box">
          <h1>
            Contact East Coast Landscapes Today! <br />
          </h1>
          <h1>
            <FaPhone
              size={22}
              style={{ color: "#ffffff", marginRight: "1rem" }}
            />
            <a href="tel:289-356-2717">289-356-2717 </a>
          </h1>
          <h1>
            <a href="mailto:eastcoastlandscapes1@gmail.com?subject=Website Inquiry">
              eastcoastlandscapes1@gmail.com{" "}
            </a>
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Form;
