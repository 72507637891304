import React from "react";
import "./BlurbStyles.css";

const Blurb = () => {
  return (
    <div>
      <div className="blurb-img">
        <div className="heading">
          <h1>
            <p>
              East Coast Landscapes is a Durham Based Hardscape Construction
              Company with over 25 Years Experience.
              <br />
              <br />
              We specialize in projects such as:
              <br />
              <br /> Interlocking Brick
              <br /> Patios and Driveways
              <br /> Retaining Walls
              <br />
              Steps
              <br /> Fences
              <br /> Decks
              <br /> Landscape Lighting
              <br /> Fire Pits
              <br /> Sodding
              <br /> and More!
              <br />
              <br />
            </p>
          </h1>

          <h1>
            <p>
              Contact Mike @ <a href="tel:289-356-2717">289-356-2717 </a>
              or{" "}
              <a href="mailto:eastcoastlandscapes1@gmail.com?subject=Website Inquiry">
                email us{" "}
              </a>
              to get a quote today!
            </p>
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Blurb;
