import React from "react";
import "./ReviewsStyles.css";

const Reviews = () => {
  return (
    <div className="reviews">
      <div className="reviews-box">
        <iframe
          title="FB review 1"
          src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fnadia.ramprasad.1%2Fposts%2F10166191219875402&width=500&show_text=true&appId=102769793544661&height=324"
          width="375"
          height="400"
          scrolling="no"
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
        ></iframe>

        <iframe
          title="FB review 4"
          src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Ftim.morrison.353250%2Fposts%2F10165157703035634&show_text=true&width=500"
          width="375"
          height="335"
          scrolling="no"
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
        ></iframe>

        <iframe
          title="FB review 2"
          src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Flynn.theriault.31%2Fposts%2F10160026419846823&show_text=true&width=500"
          width="375"
          height="222"
          scrolling="no"
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
        ></iframe>

        <iframe
          title="FB review 3"
          src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Feimear.oconnell.5%2Fposts%2F5474471815912997&show_text=true&width=500"
          width="375"
          height="189"
          scrolling="no"
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
        ></iframe>
      </div>
    </div>
  );
};

export default Reviews;
