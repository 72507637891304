import React from "react";
import "./ServicesContentStyles.css";
import ECL1 from "../assets/ECL-1.jpg";
import ECL2 from "../assets/ECL-2.jpg";

const ServicesContent = () => {
  return (
    <div className="product">
      <div className="product-inner">
        <div className="left"></div>
        <div className="right">
          <div className="img-container">
            <div className="image-stack top">
              <img src={ECL2} className="img" alt="" />
            </div>
            <div className="image-stack bottom">
              <img src={ECL1} className="img" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesContent;
